import React from "react";
import { Grid, Typography } from '@mui/material';
import Banner from '../image/congrats-bg.png';
import EarnKaro from '../image/EARNKARO.png';
import Check from '../image/right-icon.png';

export default function congrats() {
    return (
        <Grid container >
            <Grid item xs={12} sm={12} md={4} lg={4}> </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}
                sx={{
                    backgroundImage: `url(${Banner})`, // Use the imported image
                    backgroundSize: 'cover',
                    backgroundPosition: 'center', minHeight: '100vh',
                }}>
                <Grid container >
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                        <img src={EarnKaro} alt="EarnKaro" style={{ width: '134px', paddingTop: '3rem' }} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop:"40%" }}>
                        <img src={Check} alt="Check" style={{ width: '120px', height: '120px' }} />
                        <Typography px={4} pt={2} sx={{ fontFamily: 'Lufga', textAlign: 'center', color: '#00FF85', fontSize: '28px', fontWeight: '700', lineHeight: '32.5px' }}>Congratulations!</Typography>
                        <Typography px={4} pb={4} pt={1.5} sx={{ fontFamily: 'Lufga', textAlign: 'center', color: '#fff', fontSize: '20px', fontWeight: '500', lineHeight: '30px' }}>We will contact you within 72 hours!</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                {/* This Grid item is empty, used for spacing */}
            </Grid>
        </Grid>
    );
}
