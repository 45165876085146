import React from "react";
import { Grid, Typography, Box, Button } from '@mui/material';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import Banner from '../image/banner.png';
import BannerGif from '../image/inf-marketing.gif';
import EarnKaro from '../image/EARNKARO.png';
import Section2 from '../image/section2.png';
import Line from '../image/line.png';
import Earning from '../image/earning.png';
import Avator from '../image/avator1.png';
import Tata from '../image/tata.png';
import Highlight from '../image/highlight.png';
import Coupon from '../image/coupon.png';
import Coupon2 from '../image/coupon2.png';
import Slide1 from '../image/slide1.jpg';
import Slideimg from '../image/slideimg.png';
import EagleTech from '../image/eagletech.png';
import CoolTech from '../image/cooltech.png';
import Abdul from '../image/abdul.png';
import Nikhil from '../image/nikhil.png';
import Signup from '../image/signUp.png';
import Slide2 from '../image/slide2.jpg';
import Slide3 from '../image/slide3.jpg';


import { Swiper, SwiperSlide } from 'swiper/react';
import icon from '../image/receipt.png';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';


export default function Index() {
    return (
        <Grid container sx={{ background: '#fff' }} mx={0} px={0}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                {/* This Grid item is empty, used for spacing */}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid container sx={{
                    backgroundImage: `url(${Banner})`, // Use the imported image
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: 'auto',
                    display: 'flex',
                    // This ensures that items are stacked vertically
                    alignItems: 'center',
                    // justifyContent: 'center',
                    borderBottomLeftRadius: '40px',
                    borderBottomRightRadius: '40px'
                }}>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={EarnKaro} alt="EarnKaro" style={{ width: '134px', paddingTop: '3rem' }} />
                        <img src={BannerGif} alt="BannerGif" style={{
                            width: '330px',
                            height: '330px',
                        }} />
                        <Typography px={4} pb={4} sx={{ fontFamily: 'Lufga', textAlign: 'center', color: '#fff', fontSize: '1.5rem', fontWeight: '700', lineHeight: '35px' }}>
                            Join <span style={{ color: '#00FF85' }}>India's Biggest</span> Influencer
                            <span style={{ color: '#00FF85' }}> Monetisation</span> Platform!
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} px={4}>
                        <img src={Section2} alt="Section2" style={{ width: '100%', paddingTop: '32px' }} />
                    </Grid>
                    <Grid item xs={12} px={8} pt={1.5}>
                        <Typography sx={{ fontFamily: 'Lufga', color: '#485565', fontWeight: '500', fontSize: '0.875rem', lineHeight: '21px', textAlign: 'center' }}>
                            Partner with 200+ brands and get paid for every purchase made through your recommendations. Transfer this to your bank, at any time.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} px={8} pt={3.5}>
                        <img src={Line} alt="line" style={{ width: '100%' }} />
                    </Grid>
                </Grid>
                <Grid container px={2} pt={2.5}>
                    <Grid item xs={4} pt={5.5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={Avator} alt="Avator" style={{ width: '82px', height: '90px' }} />
                        <Typography pt={1} sx={{ fontSize: '0.75rem', fontWeight: '500', color: '#485565', lineHeight: '15.6px', fontFamily: 'Lufga' }}>Trusted by</Typography>
                        <Typography pt={0.5} sx={{ color: '#485565', fontWeight: '600', fontSize: '1rem', lineHeight: '17.6px', fontFamily: 'Lufga', textAlign: 'center' }}>3M+ Influencers</Typography>
                    </Grid>

                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={Tata} alt="Tata" style={{ width: '102px', height: '114px' }} />
                        <Typography pt={1} sx={{ fontSize: '0.75rem', fontWeight: '500', color: '#485565', lineHeight: '15.6px', fontFamily: 'Lufga' }}>Backed by</Typography>
                        <Typography pt={0.5} sx={{ color: '#485565', fontWeight: '600', fontSize: '1rem', lineHeight: '17.6px', fontFamily: 'Lufga', textAlign: 'center' }}>Mr. Ratan Tata</Typography>

                    </Grid>
                    <Grid item xs={4} pt={5.5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={Earning} alt="Earning" style={{ width: '82px', height: '90px' }} />
                        <Typography pt={1} sx={{ fontSize: '0.75rem', fontWeight: '500', color: '#485565', lineHeight: '15.6px', fontFamily: 'Lufga' }}>Paid over</Typography>
                        <Typography pt={0.5} sx={{ color: '#485565', fontWeight: '600', fontSize: '1rem', lineHeight: '17.6px', fontFamily: 'Lufga', textAlign: 'center' }}>100M in Earnings</Typography>
                    </Grid>
                </Grid>
                <Grid container px={2} pt={7}>
                    <Grid item={12}>
                        <Typography sx={{ color: '#485565', fontFamily: 'Lufga', fontWeight: '700', fontSize: '1.25rem', lineHeight: '26px' }}>You just can’t swipe left</Typography>
                        <img src={Highlight} alt="highlight" style={{ width: '100%', paddingTop: '15px' }} />
                        <img src={Coupon} alt="Coupon" style={{ width: '100%', paddingTop: '10px' }} />
                    </Grid>
                </Grid>
                <Box px={2} pt={2}>
                    <Grid item xs={12} sx={{ borderRadius: '16px', border: '1px solid #C1E3D2', background: 'radial-gradient(365.62% 385.67% at 50% 0%, #FFF 20%, #00FF85 100%)', boxShadow: '0px 2px 0px 0px #C1E3D2' }}>

                        <Swiper
                            modules={[Pagination, Autoplay]}
                            spaceBetween={10}
                            slidesPerView={1}
                            height='200px'
                            autoplay={{
                                delay: 1800,  // Delay between transitions (in ms). Adjust as needed.
                                disableOnInteraction: false  // Continue autoplay after user interactions.
                            }}
                            pagination={{
                                clickable: true,
                                bulletClass: 'swiper-pagination-bullet',  // ensure this matches your Swiper's CSS class
                                bulletActiveClass: 'swiper-pagination-bullet-active'
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                        >
                            <SwiperSlide><img src={Slide1} alt="Slide1" style={{ width: '100%', borderRadius: '16px' }} /></SwiperSlide>
                            <SwiperSlide><img src={Slide2} alt="Slide2" style={{ width: '100%', borderRadius: '16px' }} /></SwiperSlide>
                            <SwiperSlide><img src={Slide3} alt="Slide3" style={{ width: '100%', borderRadius: '16px' }} /></SwiperSlide>

                        </Swiper>
                        <Typography pt={1.2} pb={1.8} sx={{ color: '#32445A', fontWeight: '600', fontSize: '0.875rem', fontFamily: 'Lufga', textAlign: 'center' }}>International trips, contests & prizes</Typography>
                    </Grid>
                </Box>

                <Grid item xs={12} px={2} pt={2}>
                    <img src={Coupon2} alt="Coupon2" style={{ width: '100%', paddingTop: '10px' }} />
                </Grid>
                <Grid item xs={12} px={2} pt={9}>
                    <Typography sx={{ color: '#485565', fontFamily: 'Lufga', fontSize: '1.25rem', fontWeight: '700', lineHeight: '26px', textAlign: 'center' }}>Been here, Done that!</Typography>
                </Grid>
                <Grid container pt={4.5} alignItems="center">
                    <Grid item xs={4}>
                        <img src={EagleTech} alt="EagleTech" style={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={8} px={2.5}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px', color: '#000', fontFamily: 'Lufga' }}>
                            "You don't have to make big efforts to earn big on EarnKaro"
                        </Typography>
                        <Typography pt={1} sx={{ fontSize: '1rem', fontWeight: '600', lineHeight: '17.6px', color: '#000', fontFamily: 'Lufga' }}>
                            EagleTek, <span style={{fontSize:'0.75rem'}}>500K+ subscribers</span></Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} py={3}>
                    <img src={Line} alt="line" style={{ width: '100%' }} />
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={8} px={2.5} sx={{ textAlign: 'right' }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px', color: '#000', fontFamily: 'Lufga' }}>
                            "I have been involved with EarnKaro from the very beginning & I easily earn Rs. 50,000 in a month"
                        </Typography>
                        <Typography pt={1} sx={{ fontSize: '1rem', fontWeight: '600', lineHeight: '17.6px', color: '#000', fontFamily: 'Lufga' }}>
                            KoolTech, <span style={{fontSize:'0.75rem'}}>185K+ subscribers</span></Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <img src={CoolTech} alt="CoolTech" style={{ width: '100%' }} />
                    </Grid>
                </Grid>
                <Grid item xs={12} py={3}>
                    <img src={Line} alt="line" style={{ width: '100%' }} />
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <img src={Abdul} alt="abdul" style={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={8} px={2.5}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px', color: '#000', fontFamily: 'Lufga' }}>
                            "I’ve been using EarnKaro to access financial and non-financial campaigns and it’s been a game changer for my income through YouTube, Facebook, and Instagram"
                        </Typography>
                        <Typography pt={1} sx={{ fontSize: '1rem', fontWeight: '600', lineHeight: '17.6px', color: '#000', fontFamily: 'Lufga' }}>
                            Abdul Rasheed, <span style={{fontSize:'0.75rem'}}>140K+ subscribers</span></Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} py={3}>
                    <img src={Line} alt="line" style={{ width: '100%' }} />
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={8} px={2.5} sx={{ textAlign: 'right' }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px', color: '#000', fontFamily: 'Lufga' }}>"I use EarnKaro for my gadget review links and have always received my profits on time. Additionally, I promote various credit cards that really help save money through EarnKaro links. Overall, I can highly recommend EarnKaro for creating affiliate links and 2X your affiliate earnings"</Typography>
                        <Typography pt={1} sx={{ fontSize: '1rem', fontWeight: '600', lineHeight: '17.6px', color: '#000', fontFamily: 'Lufga' }}>
                        Technik India, <span style={{fontSize:'0.75rem'}}>60K+ subscribers</span></Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <img src={Nikhil} alt="Nikhil" style={{ width: '100%' }} />
                    </Grid>
                </Grid>
                {/* <Grid container sx={{ position: 'relative', background: 'transparent', paddingTop: '200px', zIndex: '999999' }}>
                    <Grid item xs={12} sm={12} md={4} lg={4}
                        sx={{ position: 'fixed', bottom: 0, width: '100%' }}
                    >
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <a href="/login">
                                <img src={Signup} alt="Signup" style={{ width: '100%' }} />
                            </a>
                        </Grid>
                    </Grid>
                </Grid> */}
                

                <Grid container  sx={{ position: 'relative', background: 'transparent', paddingTop: '100px',zIndex:'999999', }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} px={2}
                        sx={{ position: 'fixed', bottom: 0, width: '100%',background:'#fff',padding:'1rem' }}
                    >
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <a href="/login" style={{ width: '100%',textDecoration: 'none' }}>
                        <Button type="submit" variant="contained" sx={{ background: 'linear-gradient(180deg, #00A355 0%, #00582E 100%)', width: '100%', padding: '12px', borderRadius: '12px', fontSize: '1rem', fontWeight: '500', textTransform: 'capitalize',fontFamily:'Lufga' }}>
                        <img src={icon} alt="icon" style={{ marginRight: '8px', height: '24px', width: '24px' }} />  Sign Up Now
                                    </Button></a>
                                    
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
                {/* This Grid item is empty, used for spacing */}
            </Grid>
        </Grid>
    );
}
