import React, { useRef, useState } from 'react';
import { Grid, Typography, Box, InputAdornment, Button, ButtonBase } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import Banner from '../image/bg2.png';
import EarnKaro from '../image/EARNKARO.png';
import { styled } from '@mui/system';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import OtherIcon from '@mui/icons-material/Dashboard';
import BlogIcon from '@mui/icons-material/Article';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '../components/alert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
// Yup validation schema
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required('Name is required')
        .matches(/^[a-zA-Z\s]+$/, 'Name must only contain letters'),
    mobileNumber: Yup.string()
        .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits')
        .required('Mobile Number is required'),
    // otp: Yup.array()
    //     .of(
    //         Yup.string()
    //             .length(1, 'Each OTP digit must be exactly 1 character')
    //             .matches(/^\d$/, 'OTP must only contain numbers')
    //             .required('OTP is required')
    //     )
    //     .min(4, 'OTP must be exactly 4 digits')
    //     .max(4, 'OTP must be exactly 4 digits'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    platforms: Yup.array().of(
        Yup.object().shape({
            platform: Yup.string().required('Platform selection is required'),
            url: Yup.string()
                .trim()
                .url('Invalid URL format')
                .required('URL is required'),
            subscribers: Yup.number()
                .typeError('Subscriber Count must be a number')
                .integer('Subscriber Count must be an integer')
                .min(1, 'Subscriber Count must be greater than 0')
                .required('Subscriber Count is required'),
        })
    ),
});

const OtpInput = styled(TextField)({
    '& input': {
        textAlign: 'center',
        padding: '5px',
        fontSize: '20px',
        fontWeight: '500',
        color: '#444444',
        width: '48px',
        height: '48px',
        borderRadius: '12px', // Rounded corners
        border: '1px solid #D3D3D3', // Light grey border
        backgroundColor: 'transparent', // Light grey background
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none', // Remove the default border
        },
        '&:hover fieldset': {
            border: 'none', // Remove the border on hover
        },
        '&.Mui-focused fieldset': {
            border: 'none', // Remove the border when focused
        },
    },
});

const PlatformButton = styled(ButtonBase)(({ theme, selected }) => ({
    display: 'flex',
    width: '56px', // Set the width of each button
    height: '56px', // Set the height of each button
    borderRadius: '20px', // Rounded corners
    border: selected ? '2px solid #00C853' : '2px solid #11111333', // Green border if selected
    backgroundColor: selected ? '#fff' : '#fff', // Light green background if selected
    color: selected ? '#00C853' : '#000',
    '&:hover': {
        borderColor: '#00C853',

    },
}));

const platforms = [
    { name: 'Facebook', icon: <FacebookIcon style={{ fontSize: 32, color: '#444444' }} /> },
    { name: 'Instagram', icon: <InstagramIcon style={{ fontSize: 32, color: '#444444' }} /> },
    { name: 'Youtube', icon: <YouTubeIcon style={{ fontSize: 32, color: '#444444' }} /> },
    { name: 'Telegram', icon: <TelegramIcon style={{ fontSize: 32, color: '#444444' }} /> },
    { name: 'Blogs', icon: <BlogIcon style={{ fontSize: 32, color: '#444444' }} /> },
    { name: 'Others', icon: <OtherIcon style={{ fontSize: 32, color: '#444444' }} /> },
];

const CustomButton = styled(Button)(({ theme }) => ({
    border: '2px dashed #00C853', // Green dashed border
    borderRadius: '12px', // Rounded corners
    padding: theme.spacing(1.5, 3), // Padding for button
    color: '#00AB59', // Green text color
    textTransform: 'none', // Keep text as it is (no uppercase)
    fontWeight: '500',
    fontSize: '16px',
    display: 'flex',
    fontFamily: 'Lufga',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
        backgroundColor: '#E8F5E9', // Light green background on hover
        borderColor: '#00C853', // Keep the border color the same on hover
    },
}));

const RemoveButton = styled(Button)(({ theme }) => ({
    color: '#FF1744', // Red color for the text and icon
    border: '1px solid #FF1744', // Red border
    borderRadius: '6px', // Rounded corners
    padding: theme.spacing(0.5, 1), // Padding inside the button
    textTransform: 'none', // Keep text as it is (no uppercase)
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        backgroundColor: '#FFEBEE', // Light red background on hover
    },
}));



export default function Login() {
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const [alertState, setAlertState] = useState({ open: false, severity: '', message: '' });
    const [showOtpSection, setShowOtpSection] = useState(false)
    const [otpVerified, setOTPVerified] = useState('')
    const [otpText, setOtpText] = useState('SEND OTP')
    const navigate = useNavigate()

    const initialValues = {
        name: '',
        mobileNumber: '',
        otp: ['', '', '', ''],
        email: '',
        platforms: [{ platform: 'Youtube', url: '', subscribers: '' }],
    };

    const handleSubmit = (values) => {
        fetch(`${process.env.REACT_APP_SITE_URL}/register`, {  // Replace with the actual API endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'ce0d81b1e6477c9fbbf86003bbd36c59', // If authentication is required, replace with actual token
            },
            body: JSON.stringify({
                phoneNumber: values?.mobileNumber,
                email: values?.email,
                platforms: values?.platforms,
                otp: values?.otp?.join(''),
            }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then((errorData) => {
                        throw new Error(errorData?.message);
                    });
                }
                return response.json();
            })
            .then(data => {
                navigate('/congrats');
                setAlertState({
                    open: true,
                    severity: 'success',
                    message: 'User Register successfully',
                });
                // Handle success (e.g., navigate to the next step)
            })
            .catch(error => {
                
                setAlertState({
                    open: true,
                    severity: 'error',
                    message: error?.message || 'An error occurred',
                });
            });
    };


    const handleOtpChange = (e, index, setFieldValue, values) => {
        setOTPVerified('')
        let otp = values?.otp
        const value = e.target.value;

        // Set the value for the current field
        setFieldValue(`otp[${index}]`, value);

        // Move to the next input if a digit is entered
        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }

        // If the backspace key is pressed and the field is empty, move to the previous input
        if (e.key === 'Backspace' && value === '' && index > 0) {
            inputRefs[index - 1].current.focus();
        }

        // Check if all OTP boxes are filled
        const updatedOtp = [...otp];
        updatedOtp[index] = value;

        if (updatedOtp.every(digit => digit.length === 1)) {
            // All OTP boxes are filled, trigger the API call
            handleSubmitOtp(values?.mobileNumber, updatedOtp.join(''));
        }
    };

    const handleSubmitOtp = (mobile, otp) => {
        // Make the API call with the complete OTP
        fetch(`${process.env.REACT_APP_SITE_URL}/verify-otp`, {  // Replace with the actual API endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'ce0d81b1e6477c9fbbf86003bbd36c59', // If authentication is required, replace with actual token
            },
            body: JSON.stringify({
                phoneNumber: mobile,
                otp: otp
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to verify OTP');
                }
                return response.json();
            })
            .then(data => {
                setOTPVerified('success')
                setAlertState({
                    open: true,
                    severity: 'success',
                    message: 'OTP verified successfully',
                });
            })
            .catch(error => {
                setOTPVerified('error')
                setAlertState({
                    open: true,
                    severity: 'error',
                    message: error?.message,
                });
            });
    };


    const handleSendOtp = (mobile, errors) => {

        if (errors?.mobileNumber) {
            setAlertState({
                open: true,
                severity: 'error',
                message: errors?.mobileNumber,
            });
            return;
        }

        if (!mobile) {
            setAlertState({
                open: true,
                severity: 'error',
                message: 'Mobile Number is required',
            });
            return
        }

        fetch(`${process.env.REACT_APP_SITE_URL}/send-otp`, {  // Replace with the actual API endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'ce0d81b1e6477c9fbbf86003bbd36c59', // If authentication is required, replace with actual token
            },
            body: JSON.stringify({
                phoneNumber: mobile
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to send OTP');
                }
                return response.json();
            })
            .then(data => {
                setShowOtpSection(true)
                setOtpText('Resend OTP')

                setAlertState({
                    open: true,
                    severity: 'success',
                    message: 'OTP sent successfully',
                });
            })
            .catch(error => {
                setAlertState({
                    open: true,
                    severity: 'error',
                    message: error?.message,
                });
            });
    }


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
                <Form>
                    <Grid container sx={{ background: '#fff' }} mx={0} px={0}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            {/* This Grid item is empty, used for spacing */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Grid container sx={{
                                backgroundImage: `url(${Banner})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                borderBottomLeftRadius: '40px',
                                borderBottomRightRadius: '40px'
                            }}>
                                <Grid item xs={12} px={4}>
                                    <a href='/' style={{textDecoration:'none'}}>
                                    <img src={EarnKaro} alt="EarnKaro" style={{ width: '134px', paddingTop: '36px' }} />
                                    </a>
                                    <Typography sx={{ fontFamily: 'Lufga', color: '#fff', fontSize: '1.25rem', fontWeight: '700', lineHeight: '23px' }}>
                                        Ready for some <span style={{ color: '#00FF85' }}>International adventures?</span>
                                    </Typography>
                                    <Typography pt={1} pb={4} sx={{ color: '#fff', fontWeight: '400', fontSize: '0.875rem', fontFamily: 'Lufga' }}>Based on your performance, You could snag International trips, Extra bonuses, and Amazon gift vouchers every month. Don’t just dream about it - Let's make it happen, Together!</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} px={2} pt={3.25}>
                                    <Typography sx={{ color: '#485565', fontWeight: '700', fontSize: '1.25rem', fontFamily: 'Lufga', lineHeight: '26px' }}>Personal Details</Typography>
                                </Grid>
                                <Grid item xs={12} px={2} pt={3.25}>
                                    <Field
                                        name="name"
                                        as={TextField}
                                        placeholder="Name*"
                                        fullWidth
                                        error={touched.name && Boolean(errors.name)}
                                        // helperText={touched.name && errors.name}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-input::placeholder': {
                                                    fontSize: '1rem',
                                                    color: '#11111399',
                                                    fontWeight: '400',
                                                    fontFamily: 'lufga',
                                                    opacity: 1,
                                                },
                                                borderRadius: '12px',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} px={2} pt={3.25}>
                                    <Field
                                        name="mobileNumber"
                                        as={TextField}
                                        placeholder="Mobile Number*"
                                        fullWidth
                                        error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                                        // helperText={touched.mobileNumber && errors.mobileNumber}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '12px',
                                                paddingRight: 0,
                                            },
                                            '& .MuiOutlinedInput-input::placeholder': {
                                                fontSize: '1rem',
                                                color: '#11111399',
                                                fontWeight: '400',
                                                fontFamily: 'lufga',
                                                opacity: 1,
                                            },
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        onClick={() => handleSendOtp(values?.mobileNumber, errors)}
                                                        variant="text"
                                                        sx={{ color: '#00AB59', fontWeight: '600', fontSize: '0.75rem', lineHeight: '16.8px', fontFamily: 'Lufga' }}
                                                    >
                                                        {otpText ? otpText : "SEND OTP"}
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                {showOtpSection && <Box pt={2} px={2}>
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontFamily: 'Lufga', fontWeight: '400', fontSize: '0.875rem', color: '#11111399' }}>
                                        Verify OTP*
                                    </Typography>
                                    <Box display="flex" gap={2}>
                                        {values.otp.map((_, index) => (
                                            <Field
                                                key={index}
                                                name={`otp[${index}]`}
                                                as={OtpInput}
                                                type="number"
                                                inputProps={{ maxLength: 1,
                                                    pattern: "[0-9]*"
                                                 }}
                                                inputRef={inputRefs[index]}
                                                onChange={(e) => handleOtpChange(e, index, setFieldValue, values)}
                                                onKeyDown={(e) => handleOtpChange(e, index, setFieldValue, values)}
                                            />
                                        ))}
                                    </Box>

                                    {(otpVerified === "success" || otpVerified === "error") && <Box mt={1} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: otpVerified === "success" ? "#00AB59" : otpVerified === "error" ? "#db0011" : "",
                                        fontFamily: "Lufga",
                                        fontSize: "0.75rem",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                    }}><ErrorOutlineIcon style={{
                                        width: "0.8125rem",
                                        height: "0.8125rem"
                                    }} />&nbsp;{otpVerified === "success" ? "OTP Verified" : otpVerified === "error" ? "Incorrect OTP" : ""}</Box>}
                                </Box>}
                                <Grid item xs={12} px={2} pt={3.25}>
                                    <Field
                                        name="email"
                                        as={TextField}
                                        placeholder="Email*"
                                        fullWidth
                                        error={touched.email && Boolean(errors.email)}
                                        // helperText={touched.email && errors.email}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-input::placeholder': {
                                                    fontSize: '1rem',
                                                    color: '#11111399',
                                                    fontWeight: '400',
                                                    fontFamily: 'lufga',
                                                    opacity: 1,
                                                },
                                                borderRadius: '12px',
                                            },
                                        }}
                                    />
                                </Grid>

                                <FieldArray name="platforms">
                                    {({ remove, push }) => (
                                        <>
                                            {values.platforms.map((platform, index) => (
                                                <Grid key={index} container>
                                                    <Box px={2} pt={2}>
                                                        <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
                                                            <Typography variant="subtitle1" sx={{ fontFamily: 'Lufga', fontSize: '0.875rem', fontWeight: '400', color: '#11111399' }}>
                                                                Platform {index + 1}*
                                                            </Typography>
                                                            {index > 0 && <RemoveButton onClick={() => remove(index)} sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Lufga' }}>
                                                                Remove
                                                                <DeleteIcon sx={{ marginLeft: '4px' }} />
                                                            </RemoveButton>}
                                                        </Box>
                                                        <Box display="flex" flexWrap="wrap">
                                                            {platforms.map((platformOption) => (
                                                                <Box key={platformOption.name} textAlign="center" m={1}>
                                                                    <PlatformButton
                                                                        selected={values.platforms[index].platform === platformOption.name}
                                                                        onClick={() => {
                                                                            setFieldValue(`platforms[${index}].platform`, platformOption.name);
                                                                        }}
                                                                    >
                                                                        {platformOption.icon}
                                                                    </PlatformButton>
                                                                    <Typography
                                                                        variant="caption"
                                                                        style={{
                                                                            marginTop: '8px',
                                                                            color: values.platforms[index].platform === platformOption.name ? '#00C853' : '#555',
                                                                            fontWeight: values.platforms[index].platform === platformOption.name ? '500' : '500',
                                                                            fontFamily: 'Lufga',
                                                                        }}
                                                                    >
                                                                        {platformOption.name}
                                                                    </Typography>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    </Box>
                                                    <Grid item xs={12} px={2} pt={3.25}>
                                                        <Field
                                                            name={`platforms[${index}].url`}
                                                            as={TextField}
                                                            placeholder="Platform URL*"
                                                            fullWidth
                                                            error={touched.platforms?.[index]?.url && Boolean(errors.platforms?.[index]?.url)}
                                                            // helperText={touched.platforms?.[index]?.url && errors.platforms?.[index]?.url}
                                                            InputProps={{
                                                                sx: {
                                                                    '& .MuiOutlinedInput-input::placeholder': {
                                                                        fontSize: '1rem',
                                                                        color: '#11111399',
                                                                        fontWeight: '400',
                                                                        fontFamily: 'Lufga',
                                                                        opacity: 1,
                                                                    },
                                                                    borderRadius: '12px',
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} px={2} pt={3.25}>
                                                        <Field
                                                            name={`platforms[${index}].subscribers`}
                                                            as={TextField}
                                                            placeholder="Subscriber Count*"
                                                            fullWidth
                                                            error={touched.platforms?.[index]?.subscribers && Boolean(errors.platforms?.[index]?.subscribers)}
                                                            // helperText={touched.platforms?.[index]?.subscribers && errors.platforms?.[index]?.subscribers}
                                                            InputProps={{
                                                                sx: {
                                                                    '& .MuiOutlinedInput-input::placeholder': {
                                                                        fontSize: '1rem',
                                                                        color: '#11111399',
                                                                        fontWeight: '400',
                                                                        fontFamily: 'lufga',
                                                                        opacity: 1,
                                                                    },
                                                                    borderRadius: '12px',
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Grid item xs={12} px={2} pt={3.25} pb={8}>
                                                <CustomButton onClick={() => push({ platform: '', url: '', subscribers: '' })}>
                                                    Add More Platform
                                                    <AddIcon style={{ marginLeft: '8px', color: '#fff', background: '#00AB59', borderRadius: '50px' }} />
                                                </CustomButton>
                                            </Grid>
                                        </>
                                    )}
                                </FieldArray>

                                <Grid container sx={{ position: 'relative', background: 'transparent', paddingTop: '200px', zIndex: '999999', }}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} px={2}
                                        sx={{ position: 'fixed', bottom: 0, width: '100%', background: '#fff', padding: '1rem' }}
                                    >
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                            <Button type="submit" variant="contained" sx={{ background: 'linear-gradient(180deg, #00A355 0%, #00582E 100%)', width: '100%', padding: '12px', borderRadius: '12px', fontSize: '1rem', fontWeight: '500', textTransform: 'capitalize', fontFamily: 'Lufga' }}>
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={12} px={2} pb={2} sx={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 1000 }}>
                                    <Button type="submit" variant="contained" sx={{ background: 'linear-gradient(180deg, #00A355 0%, #00582E 100%)', width: '100%', padding: '12px', borderRadius: '12px', fontSize: '1rem', fontWeight: '500', textTransform: 'capitalize' }}>
                                        Submit
                                    </Button>
                                </Grid> */}
                                <Alert data={alertState} setAlert={setAlertState} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            {/* This Grid item is empty, used for spacing */}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
