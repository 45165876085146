import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './routes';
function App() {
  return (
    <BrowserRouter>
    <Router />
    </BrowserRouter>
  // <h1>hello</h1>
  );
}

export default App;