import * as React from "react";
import { useRoutes } from 'react-router-dom';
import Index from "./pages";
import Login from "./pages/login";
import Congrats from './pages/congrats';

export default function Router() {
  const routes = [
    {
      path: '/',
      element: <Index />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
        path: '/congrats',
        element: <Congrats />,
      }
  ];

  return useRoutes(routes);
}
