import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Snakbar(props) {
    const { data, setAlert } = props;
    const vertical = 'bottom';
    const horizontal = 'right';
  
    const handleClose = () => {
      setAlert({ ...data, open: false });
    };
  
    return (
      <Stack spacing={2} sx={{ width: '100%',zIndex:'9999999' }}>
        <Snackbar 
          open={data.open} 
          autoHideDuration={2000} 
          onClose={handleClose} 
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert 
            onClose={handleClose} 
            severity={data.severity || 'error'} 
            sx={{ width: '100%' }}
          >
            {data?.message}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }
